// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FridgeList.css */
.fridge-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 20px;
    position: relative;
}

.fridge-item {
    border: 1px solid #ddd;
    padding: 10px;
    height: 16rem;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.fridge-image {
    /* width: 100%; */
    /* max-height: 100px; */
    object-fit: contain;
    height: 10rem;
    margin-bottom: 8px;
}

.fridge-details-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
}


.fridge-details {
    font-family: Arial, sans-serif;
    color: #333;
    font-size: 12px;
}

.fridge-rating img {
    height: 1.5rem;
}

.fridge-price {
    font-size: 14px;
    font-weight:900;
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Components/FridgeList.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,uCAAuC;IACvC,aAAa;AACjB;;;AAGA;IACI,8BAA8B;IAC9B,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,YAAY;AAChB","sourcesContent":["/* FridgeList.css */\n.fridge-list {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 16px;\n    padding: 20px;\n    position: relative;\n}\n\n.fridge-item {\n    border: 1px solid #ddd;\n    padding: 10px;\n    height: 16rem;\n    text-align: center;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    position: relative;\n}\n\n.fridge-image {\n    /* width: 100%; */\n    /* max-height: 100px; */\n    object-fit: contain;\n    height: 10rem;\n    margin-bottom: 8px;\n}\n\n.fridge-details-popup {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 10;\n    background: white;\n    border: 1px solid #ddd;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n    padding: 20px;\n}\n\n\n.fridge-details {\n    font-family: Arial, sans-serif;\n    color: #333;\n    font-size: 12px;\n}\n\n.fridge-rating img {\n    height: 1.5rem;\n}\n\n.fridge-price {\n    font-size: 14px;\n    font-weight:900;\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
